// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const ToggleBox = ({ title, selected }) => (
    <button 
        type="button"
        style={ selected ? { lineHeight: "46.875rem" } : {}}
        className="manifest__services-button inuit-btn"
    >
        {title}
    </button>
)

ToggleBox.propTypes = {
  title: PropTypes.string,
  selected: PropTypes.bool,
}

ToggleBox.defaultProps = {
    title: "",
    selected: false,
}

export default ToggleBox
