import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

class DevArticle extends React.Component {
    render = () => (
        <React.Fragment>
            <article className="manifest__article" style={ this.articleStyle() }>
                
                <h2 className="manifest__article-heading">DÉVELOPPEMENT</h2>
                <p className="manifest__para">
                    L’étape ultime de la vie d’un projet précédant sa livraison définitive au client, lors de cette étape nous réalisons les opérations suivantes : <br/> <br/>
                    • La planification de livraison des Product Backlogs selon la méthode gestion de projet (Waterfall ou Agile) <br/><br/>
                    • La planification des itérations <br/><br/>
                    • Le développement -> Test ou TDD (développement piloté par les tests) <br/><br/>
                </p>
            
            </article>
        </React.Fragment>
    )

    constructor(props){
        super(props)
    
        this.state = {
            showMore: true,
            buttonValue: "",
            showMoreText: "Afficher plus",
            showLessText: "Afficher moins"
        }

    }

    componentDidMount = () => {
        this.toggleShowMore()
    }

    toggleShowMore = () => {
        let {showMore, showMoreText, showLessText} = this.state
        this.setState({
            showMore: !showMore,
            buttonValue: !showMore == false ? showMoreText : showLessText
        })
    }

    articleStyle = () => {
        if (this.state.showMore) return { height: "auto" }
        else return { height: "auto" }
    }

}

export default DevArticle
