import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

class GestionProjetArticle extends React.Component {
    render = () => (
        <React.Fragment>
            <article className="manifest__article" style={ this.articleStyle() }>

                <h2 className="manifest__article-heading">GESTION DE PROJETS</h2>

                <h3 className="manifest__article-sub-heading"></h3>
                <p className="manifest__para">
                    L'efficacité et l'efficience sont des aspects clés de notre approche :
                </p>
                <h3 className="manifest__article-sub-heading">Listen & share (écoute et échange) :</h3>
                <p className="manifest__para">
                    Lors du premier contact nous opérons une identification claire et précise des besoins et nous établissons ensemble, avec nos clients, les objectifs à atteindre.<br />
                    Ainsi nous développons une offre sur mesure en tenant compte des moyens et délais à respecter. 
                </p>
                <h3 className="manifest__article-sub-heading">Une conception au service de la performance  :</h3>
                <p className="manifest__para">
                    Cette étape consiste à définir les paramètres suivants : <br />
                    • Architecture<br />
                    • Choix de Technologie Backend<br />
                    • Choix de Technologie Front<br />
                    • Choix de la méthode gestion de projet (Scrum, Kanban, Lean)<br />
                    • Mise en place du product Backlog
                </p>
                <h3 className="manifest__article-sub-heading">Maquettage des interfaces graphiques :</h3>
                <p className="manifest__para">
                    • UX - Interactive Wireframes<br />
                    • UI - Interfaces Utilisateurs<br />
                </p>
                <h3 className="manifest__article-sub-heading">Présentation/tests client :</h3>
                <p className="manifest__para">
                    Lors de cette étape, nous exposons à nos clients le travail réalisé afin de recueillir leurs remarques pour ensuite opérer :  <br/>
                    <br/>
                    - Des fine-tuning sur les maquettes des interfaces graphiques : <br/>
                    <span style={{ marginLeft: "1rem" }} /> • UX - Interactive Wireframes <br/>
                    <span style={{ marginLeft: "1rem" }} /> • UI - Interfaces Utilisateurs <br/>
                    <br/>
                    - La préparation de l'infrastructure/Devops : <br/>
                         <span style={{ marginLeft: "1rem" }} /> • Mise en place de l'environnement de DEV <br/>
                         <span style={{ marginLeft: "1rem" }} /> • Mise en place de l'environnement de Recette <br/>
                         <span style={{ marginLeft: "1rem" }} /> • Mise en place de l'environnement de Production <br/>
                         <span style={{ marginLeft: "1rem" }} /> • Mise en place du mécanisme de la continious delivery <br/>
                         <span style={{ marginLeft: "1rem" }} /> • Exposition du lien de la recette
                </p>
                <h3 className="manifest__article-sub-heading"></h3>
            </article>
        </React.Fragment>
    )

    constructor(props){
        super(props)
    
        this.state = {
            showMore: true,
            buttonValue: "",
            showMoreText: "Afficher plus",
            showLessText: "Afficher moins"
        }

    }

    componentDidMount = () => {
        this.toggleShowMore()
    }

    toggleShowMore = () => {
        let {showMore, showMoreText, showLessText} = this.state
        this.setState({
            showMore: !showMore,
            buttonValue: !showMore == false ? showMoreText : showLessText
        })
    }

    articleStyle = () => {
        if (this.state.showMore) return { height: "auto" }
        else return { height: "auto" }
    }

}

export default GestionProjetArticle
